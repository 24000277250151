import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const news = () => ({
  name: BLOCK_TYPES_IRI.NEWS,
  label: 'Blok z aktualnościami',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          type: 'string',
          label: 'Tytuł bloku',
        },
        subtitle: {
          type: 'string',
          label: 'Nadtytuł bloku'
        },
      },
    },
  },
})
