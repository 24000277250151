import React from 'react'
import { Route } from 'react-router-dom'
import { AccountTreeOutlined } from '@material-ui/icons'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/bipPage'
import { ArticleEdit } from './Article/ArticleEdit'
import { ArticleNew } from './Article/ArticleNew'
import { ArticleCollection } from './Article/ArticleCollection'
import { VersionCollection } from './Version/VersionCollection'
import { VersionEdit } from './Version/VersionEdit'
import { ArticleVersionCollection } from './ArticleVersion/ArticleVersionCollection'
import { ArticleVersionEdit } from './ArticleVersion/ArticleVersionEdit'

export const PATH = '/bip_pages'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: PATH,
        exact: true,
        icon: AccountTreeOutlined,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${PATH}/new`,
        exact: true,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={Edit}
            uniqueKey={true}
            {...props}
          />
        ),
        path: `${PATH}/:id`,
        exact: true,
      },
      articles: {
        subtitle: 'T_MODULE_BIP_PAGE_ARTICLE',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ArticleCollection}
            {...props}
          />
        ),
        path: `${PATH}/:id/articles`,
        exact: true,
      },
      articleNew: {
        subtitle: 'T_MODULE_BIP_PAGE_ARTICLE_NEW_BUTTON',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ArticleNew}
            {...props}
          />
        ),
        path: `${PATH}/articles/new`,
        exact: true,
      },
      articleEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ArticleEdit}
            {...props}
          />
        ),
        path: `${PATH}/articles/:id`,
        exact: true,
      },
      versions: {
        subtitle: 'T_MODULE_BIP_PAGE_VERSION',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={VersionCollection}
            {...props}
          />
        ),
        path: `${PATH}/:id/versions`,
        exact: true,
      },
      versionEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={VersionEdit}
            {...props}
          />
        ),
        path: `${PATH}/versions/:id`,
        exact: true,
      },
      articleVersions: {
        subtitle: 'T_MODULE_BIP_PAGE_ARTICLE_VERSION',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ArticleVersionCollection}
            {...props}
          />
        ),
        path: `${PATH}/:id/article_versions`,
        exact: true,
      },
      articleVersionEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ArticleVersionEdit}
            {...props}
          />
        ),
        path: `${PATH}/article_versions/:id`,
        exact: true,
      },
    },
    {
      title: 'T_MODULE_BIP_PAGE',
      resourceAccess: schema.access,
      indexPath: PATH,
      // parent: `${schema.endpoint}/:id`,
    }
  )

export default routes
