import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const pagesTile = (
  blockMultiSectionEndpoint,
  photoEndpoint,
  blockMultiSectionThumbEndpoint,
  definitionRef = '#PageBlockMultiSection'
) => ({
  name: BLOCK_TYPES_IRI.PAGES_TILE,
  label: 'Blok z przekierowaniami do artykułów',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    multiSections: {
      type: 'collection',
      label: 'Strony',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`, //'pageTitle',
      definitionRef: definitionRef,
      endpoint: blockMultiSectionEndpoint,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
              type: 'string',
            },
            description: {
              description: 'Krótki opis pod kaflem',
              type: 'textarea',
            },
            alt: {
              description: 'Tag alt',
              type: 'string',
            },
            link: {
              description: 'Link',
              hint: 'jesli nie wpisany, można wybrac stronę poniżej',
              type: 'string',
            },
          },
        },
        page: {
          type: 'resource',
          endpoint: `/api/pages_select?pagination=false`,
          titleAccessor: `translations.${LANGS_IRI.PL}.title`, //`nativeTitle`,
          label: 'Strony',
        },
        media2: {
          type: 'media[image]',
          description: 'T_GENERAL_MEDIA_PHOTO',
          endpoint: photoEndpoint,
          thumbs: blockMultiSectionThumbEndpoint && {
            endpoint: blockMultiSectionThumbEndpoint + '?name=home_section_small',
          },
          validate: ['maxSize'],
        },
      },
    },
  },
})
