import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const homeNewsletter = () => ({
  name: BLOCK_TYPES_IRI.HOME_NEWSLETTER,
  label: 'Sekcja z newsletterem',
  properties: {
    translations: {
      type: 'translation',
      properties: {

        text: {
          type: 'textarea',
          description: 'Tekst w sekcji'
        },
      },
    },
  },
})
