import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const homeNews = (
  blockMultiSectionEndpoint,
  definitionRef = '#PageBlockMultiSection',
  withAutomatic = false,
  title = null
) => {
  let out = {
    name: BLOCK_TYPES_IRI.HOME_NEWS,
    label: title ? title : 'Blok z aktualnościami',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'string',
            label: 'Tytuł sekcji',
          },
          subtitle: {
            type: 'string',
            label: 'Nadtytuł sekcji',
          },
        },
      },
      multiSections: {
        type: 'collection',
        label: 'Aktualności',
        titleAccessor: 'newsTitle', //`translations.${LANGS_IRI.PL}.title`,
        definitionRef: definitionRef,
        endpoint: blockMultiSectionEndpoint,
        properties: {
          news: {
            type: 'resource',
            endpoint: `/api/news_select?pagination=false`,
            titleAccessor: `nativeTitle`,
            label: 'Aktualności',
          },
        },
      },
    },
  }

  if (withAutomatic) {
    out = {
      ...out,
      properties: {
        ...out.properties,
        automatic: {
          label: 'Automatycznie najnowsze aktualności',
          hint: 'wyswietla automatycznie, zamiast ręcznie wybranych'
        },
      },
    }
  }

  return out
}
