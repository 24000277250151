import React from 'react'
import { Route } from 'react-router-dom'
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit'
import { SchemableComponent } from 'components/SchemableComponent'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'

const basePath = '/config_contact'

const routes = () =>
  addRoutesConfig(
    {
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: basePath,
        exact: true,
        icon: VerticalSplitIcon,
      },
    },
    {
      title: 'Kontakt',
      resourceAccess: 'config',
    }
  )

export default routes
