import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const contactPerson = (
  blockMultiSectionEndpoint,
  definitionRef = '#PageBlockMultiSection'
) => ({
  name: BLOCK_TYPES_IRI.HEADMASTER,
  label: 'Blok z osobami',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          type: 'string',
          label: 'Tytuł sekcji'
        },
      },
    },
    multiSections: {
      type: 'collection',
      label: 'Osoby',
      titleAccessor: 'crewItemTitle', //`translations.${LANGS_IRI.PL}.title`,
      definitionRef: definitionRef,
      endpoint: blockMultiSectionEndpoint,
      properties: {

        // translations: {
        //   type: 'translation',
        //   properties: {
        //     title: {
        //       description: 'T_GENERAL_TITLE',
        //       label: 'T_GENERAL_TITLE',
        //       type: 'string',
        //     },
        //   },
        // },

        crewItem: {
          type: 'resource',
          endpoint: `/api/crew_items?pagination=false&type=actor`,
          titleAccessor: `translations.${LANGS_IRI.PL}.fullName`,
          label: 'Osoby teatru',
        },

      },
    },
  },
})
