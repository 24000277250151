import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import AttachmentIcon from '@material-ui/icons/Attachment'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import shema from '_schema/translatorEntry'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'

export const ExportImportCards = ({ reloadInvokeFunction }) => {
  const [state, setState] = useState({
    valuefile: null,
    valuecheckbox: false,
    isFetching: false,
  })

  const handleInputChange = e => {
    const valuefile = e.target.files[0]
    setState(state => ({
      ...state,
      valuefile,
    }))
  }
  const handleCheckboxChange = (e, value) => {
    const valuecheckbox = value
    setState(state => ({
      ...state,
      valuecheckbox,
    }))
  }

  const handleSend = () => {
    if (!state.valuefile) {
      notification('error', 'Załącz plik .json z tłumaczeniami.', 'Brak pliku!')
      return
    }

    const formData = new FormData()
    formData.append('file', state.valuefile)
    formData.append('overwrite', state.valuecheckbox)

    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetchDataHandleAuthError(
      `${shema.endpoint}/import_translations`,
      'POST',
      { body: formData },
      response => {
        const resource = response
        uploadSuccess(resource)
      },
      error => {
        uploadFailure(error)
      },
      { 'Content-Type': 'multipart/form-data' }
    )
  }

  const uploadSuccess = () => {
    reloadInvokeFunction(false)
    setState(state => ({
      ...state,
      valuefile: null,
      isFetching: false,
    }))
    notification('success', 'Import przebiegł poprawnie', 'Wczytano')
    reloadInvokeFunction(true)
  }

  const uploadFailure = error => {
    if (error.response.title === 'AbortError') {
      return
    }

    setState(state => ({
      ...state,
      isFetching: false,
    }))

    notification('error', error.response.detail, error.response.title)
  }

  const exportAll = () => {
    let requests = []
    requests.push({
      url: `${shema.endpoint}/exportselected`,
      method: 'POST',
      body: {
        items: [],
      },
    })

    setTimeout(() => {
      Promise.all([
        requests.map(request =>
          fetchDataHandleAuthError(
            request.url,
            request.method,
            {
              body: JSON.stringify(request.body),
            },
            response => {
              var blob = new Blob([JSON.stringify(response)])
              const url = window.URL.createObjectURL(blob)
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', `export.json`)

              // Append to html link element page
              document.body.appendChild(link)
              // Start download
              link.click()
              // Clean up and remove the link
              link.parentNode.removeChild(link)
            }
          )
        ),
      ]).then(
        () => {
          notification(
            'success',
            'T_TRANSLATOR_MASS_SELECT_EXPORT_DONE',
            'T_FORM_SUCCESS'
          )
        },
        error => {
          notification('error', error.response.detail, error.response.title)
        }
      )
    }, 500)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Card style={{ marginTop: '15px' }}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Import tłumaczeń
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.valuecheckbox}
                      onChange={handleCheckboxChange}
                      name="valuecheckbox"
                      color="primary"
                    />
                  }
                  label="nadpisz istniejące"
                />
              </Grid>
              <Grid item xs={6}>
                <input
                  accept=".json,application/json"
                  id="contained-button-file"
                  style={{ display: 'none' }}
                  multiple={false}
                  type="file"
                  onChange={handleInputChange}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" component="span">
                    <AttachmentIcon />
                    <span> plik</span>
                  </Button>
                </label>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginTop: '15px' }}
              onClick={handleSend}
            >
              Importuj
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card style={{ marginTop: '15px' }}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Eksport tłumaczeń
            </Typography>

            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginTop: '15px' }}
              onClick={exportAll}
            >
              Eksportuj wszystkie
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
