import React, { useState } from 'react'
import { Form } from 'pages'
import schema from '_schema/cycle'
import routes from './routes'
import { BlockCollection } from '../../components/block'

export const Edit = ({ schema: definitions, type, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [parentResource, setParentResource] = useState(null)

  const link = `http://${window.location.host.replace(
    'admin.',
    ''
  )}/wydarzenia/podglad/${parentResource?.uuid}/${parentResource?.accessKey}`

  const profile = null

  return (
    <div>
      {/* <Alert
        severity="info"
        style={{
          marginTop: '24px',
          marginLeft: '32px',
          marginRight: '32px',
          border: '1px solid #474e59',
        }}
      >
        <AlertTitle>
          <b>Link poglądowy</b>
        </AlertTitle>
        <a href={link}>{link}</a>
      </Alert> */}
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        collectionPath={routes().index.path}
        storeCollectionId={schema.endpoint}
        sidebar={true}
        width={'100%'}
        setParentResource={setParentResource}
      >
        <BlockCollection
          endpoint={schema.subresources.blockEvent.endpoint}
          pid={match.params.id}
          parentIri={iri}
          definitionSchema={
            definitions[schema.subresources.blockEvent.definition]
          }
          types={schema.subresources.blockEvent.types}
          contextualHint={schema.subresources.blockEvent.contextualHint}
          statable={true}
          key={iri}
        />

        {/* is off */}
        {/* <div style={{ marginTop: '30px' }}>
          <EmbeddedCollection
            endpoint={`${schema.subresources.terms.endpoint}?iri[parent]=${iri}&pagination=false`}
            pid={match.params.id}
            parentIri={iri}
            properties={schema.subresources.terms.properties}
            definitionSchema={
              definitions[schema.subresources.terms.definition]
            }
            headerTitle={translate('Terminy')}
            panelTitle={translate('Terminy')}
            titleAccessor={'title'}
            statable={true}
          />
        </div> */}
      </Form>
    </div>
  )
}
