import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/main_menus',
  access: 'mainMenu',
  resource: {
    definition: '#MainMenu',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          titleEmpty: {
            label: 'Title',
            hint: 'jeśli wpisany, będzie użyty zamiast nazwy strony',
            type: 'string'
          },
          // link: {
          //   hint: 'wpisz link lub wybierz stronę poniżej'
          // },
        },
      },

      page: {
        type: 'resource',
        endpoint: `/api/pages_select?pagination=false&exists[versionable]=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,//'uuid',
        label: 'T_BLOCK_ELEMENT_LABEL_PAGE',
        // storeCollectionId: 'pages_form_type_page',
      },

      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
