const schema = {
  endpoint: '/api/event_reviews',
  clone_endpoint: '/api/event_reviews/clone',
  access: 'event',
  resource: {
    definition: '#EventReview',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'string',
            description: 'Tytuł',
          },
          description: {
            description: 'Opis krótki na kaflu',
            type: 'textarea',
          },
          author: {
            description: 'Autor',
          },
          authorText: {
            type: 'string',
            description: 'Tekst pod autorem',
          },
          link: {
            type: 'string',
            description: 'Link do artykułu',
          },
        },
      },
      dateReview: {
        type: 'date',
        description: 'Data recenzji',
        validate: ['required'],
      },

      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
