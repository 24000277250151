import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import packageGallery from '../packageGallery'

export const gallery = () => ({
  name: BLOCK_TYPES_IRI.GALLERY,
  label: 'T_GENERAL_BLOCK_TYPE_GALLERY',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    gallery: {
      type: 'resource',
      endpoint: `${packageGallery.endpoint}?pagination=false`,
      titleAccessor: 'title',
      description: 'Select package gallery',
    },

    galleryType: {
      type: 'choice',
      description: 'Typ galleri',
      choices: {
        normal: '9 poziomych zdjęć',
        big_last: '8 zdjęć, piąte pionowe',
      },
      validate: ['required'],
    },
  },
})
