import React from 'react'
import { Form } from 'pages'
import schema from '_schema/eventTerm'
import routesEvent from 'pages/Event/routes'
import routesSpectacle from 'pages/Event/routesSpectacle'
import routesCycle from 'pages/EventCycle/routes'

export const Edit = ({ schema: definitions, type, ...rest }) => {
  const { match } = rest
  const routes =
    type === 'event'
      ? routesEvent
      : type === 'spectacle'
      ? routesSpectacle
      : routesCycle
  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().terms.path.replace(':id', match.params.pid)}
      storeCollectionId={schema.endpoint}
      sidebar={false}
      width={600}
    ></Form>
  )
}
