import React from 'react'
import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { Alert } from '@material-ui/lab'

export const multimediaEent = () => ({
  name: BLOCK_TYPES_IRI.MULTIMEDIA,
  label: 'Blok multimedia, zdjęcia',
  properties: {
    hint: {
      type: 'hint',
      hint: function() {
        return (
          <Alert severity="info" style={{ background: 'none', padding: 0 }}>
            blok wyświetla zdjęcia zamieszczone w wydarzeniu
          </Alert>
        )
      }
    }

  },
})
