import React from 'react'
import { Form } from 'pages'
import schema from '_schema/cycle'
import routes from './routes'

export const New = ({ schema: definitions, type }) => {
  const profile = null

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      method="POST"
      url={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      storeCollectionId={schema.endpoint}
      width={'100%'}
      defaultData={{ type: type }}
    />
  )
}
