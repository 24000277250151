import React, { useMemo } from 'react'
import { NotFound } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { translate } from '_helpers/translate'
import schema from '_schema/event'
import itemSchema from '_schema/eventReview'
import routes from 'pages/Event/routesSpectacle'
import { LANGS_IRI } from '_lib/langs'
import { DateCell } from 'components/table/cells/DateCell'
import { publish } from 'components/table/_mass'

export const Reviews = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const itemColumns = useMemo(
    () => [
      {
        header: translate('Tytuł recenzji'),
        accessor: `translations.${LANGS_IRI.PL}.title`,
        width: '73%',
      },

      {
        header: translate('Data recenzji'),
        accessor: `dateReview`,
        width: '10%',
        Cell: DateCell,
      },

      {
        ...operations(`title`, true, true, true, true),
        width: '15%',
      },
    ],
    []
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj recenzje')}
        buttonPath={`${routes().reviewsNew.path.replace(
          ':id',
          match.params.id
        )}`}
        hideButton={false}
      ></CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${itemSchema.endpoint}.get`}
        endpoint={itemSchema.endpoint}
        definition={itemSchema.resource.definition}
        storeCollectionId={`${itemSchema.endpoint}?iri[parent]=${parentIri}`}
        defaultFilters={{
          'iri[parent]': parentIri,
        }}
        parameters={[
          {
            name: 'iri[parent]',
            in: 'query',
            type: 'string',
            value: false,
          },
        ]}
        schemaParameters={[
          {
            name: 'order[ord]',
            in: 'query',
            type: 'string',
          },
        ]}
        columns={itemColumns}
        customResourceSchema={itemSchema.resource}
        orderable={true}
        defaultSorters={{ ord: 'desc' }}
        isRowLinkable={true}
        editPath={routes().reviewsEdit.path.replace(':pid', match.params.id)}
        autoWidth={false}
        selectable={[publish.on(), publish.off()]}
      />
    </Paper>
  )
}
