import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const textWithPhoto = (photoEndpoint, thumbEndpoint) => ({
  name: BLOCK_TYPES_IRI.TEXT_WITH_PHOTO,
  label: 'Blok tekstowy ze zdjęciem',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
        imageDescription: {
          type: 'string',
          description: 'Tytuł zdjęcia',
        },
        // link: {
        //   type: 'string',
        //   description: 'T_GENERAL_BLOCK_LINK',
        // },
        alt: {
          description: 'Tag alt',
          type: 'string',
        },
      },
    },
    // photoAuthor: {
    //   description: 'T_GENERAL_AUTHOR',
    // },
    media: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: false && thumbEndpoint && {
        // endpoint: thumbEndpoint+'?name=video_mask',
      },
      validate: ['maxSize'],
    },
    media2: {
      type: 'image',
      description: 'lub zdjęcie ETR',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint+'?name=etr',
      },
      validate: ['maxSize'],
    },
    blockPosition: {
      type: 'choice',
      choices: {
        left: 'T_GENERAL_BLOCK_POSITION_LEFT',
        right: 'T_GENERAL_BLOCK_POSITION_RIGHT',
        full: 'T_GENERAL_BLOCK_POSITION_FULL',
      },
      validate: ['required'],
    },
    // mediaThumbType: {
    //   type: 'resource',
    //   endpoint: `/api/thumb_types?pagination=false`,
    //   titleAccessor: `trans`,
    //   transTitle: true,
    //   label: 'T_GENERAL_MEDIA_PHOTO_TYPE',
    //   initialValue: { value: '/api/thumb_types/CROPPED', title: 'Kadr' },
    //   validate: ['required'],
    // },
    // file: {
    //   type: 'file',
    //   description: 'T_GENERAL_VIDEO',
    //   endpoint: photoEndpoint,
    //   validate: ['maxSize'],
    // },
    // codeYt: {
    //   description: 'T_GENERAL_CODE_YT',
    // },
  },
})
