import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import mediaSchema from '_schema/media'

export const modifyBlockByPage = (blockTypes, resource) => {
  if (!resource) return blockTypes

  let modifiedProperties = []

  switch (resource.idName) {
    // Modify by page here
    case 'plan_visit':
      modifiedProperties = blockTypes.filter(
        elem => elem.name === BLOCK_TYPES_IRI.FAQ
      )
      return modifiedProperties
    case 'education':
      modifiedProperties = blockTypes.filter(
        elem =>
          elem.name === BLOCK_TYPES_IRI.PAGES_TILE ||
          elem.name === BLOCK_TYPES_IRI.HOME_LABIRYNTH
      )
      return modifiedProperties
    case 'teatr':
      modifiedProperties = blockTypes.filter(
        elem =>
          elem.name === BLOCK_TYPES_IRI.HEADMASTER ||
          elem.name === BLOCK_TYPES_IRI.SCENES ||
          elem.name === BLOCK_TYPES_IRI.PAGES
      )
      return modifiedProperties
    case 'headmaster':
      modifiedProperties = blockTypes.filter(
        elem =>
          elem.name === BLOCK_TYPES_IRI.HEADMASTER
      )
      return modifiedProperties
    case 'contact':
      modifiedProperties = blockTypes.filter(
        elem =>
          elem.name === BLOCK_TYPES_IRI.CONTACT ||
          elem.name === BLOCK_TYPES_IRI.HEADMASTER
      )
      return modifiedProperties
    case 'repertory':
    case 'event':
    case 'event_archive':
    case 'news':
    case 'job_offers':
    case 'gallery':
    case 'special_offer':
      return []
    default:
      if (resource.parentIdName === 'gallery') {
        const thumbEndpoint = '/api/page_block_thumbs'

        modifiedProperties = blockTypes.filter(
          elem => elem.name === BLOCK_TYPES_IRI.GALLERY
        )
        modifiedProperties.forEach(element => {
          if (element.name === BLOCK_TYPES_IRI.GALLERY) {
            element.properties.translations.properties.text = {
              type: 'tinymce',
              description: 'Opis galerii',
            }
            element.properties.translations.properties.alt = {
              type: 'string',
              label: 'Tag alt'
            }
            element.properties.translations.properties.slug = {
              type: 'string',
            }
          }
          element.properties.media2 = {
            type: 'image',
            description: 'Zdjęcie kafelka zbiorczego',
            endpoint: mediaSchema.endpoint,
            thumbs: thumbEndpoint && {
              endpoint: thumbEndpoint + '?name=etr',
            },
            validate: ['maxSize'],
          }
        })
        return modifiedProperties
      }

      modifiedProperties = blockTypes.filter(
        elem =>
          elem.name !== BLOCK_TYPES_IRI.PAGES_TILE &&
          elem.name !== BLOCK_TYPES_IRI.HOME_LABIRYNTH &&
          elem.name !== BLOCK_TYPES_IRI.CONTACT &&
          elem.name !== BLOCK_TYPES_IRI.HEADMASTER &&
          elem.name !== BLOCK_TYPES_IRI.SCENES
      )
      return modifiedProperties
  }
}
