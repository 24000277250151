// import fileSchema from './media'

const schema = {
  endpoint: '/api/package_pricelists',
  access: 'packagePricelist',
  resource: {
    definition: '#PackagePricelist',
    properties: {
      title: {
        description: 'Nazwa cennika',
        hint: 'widoczna tylko w PA',
        validate: ['required'],
      },
      translations: {
        type: 'translation',
        properties: {

          title: {
            description: 'Tytuł/Nagłówek cennika',
          },

        },
      },
    },
  },
  subresources: {
    item: {
      endpoint: '/api/package_pricelist_items',
      definition: '#PackagePricelistItem',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            strefe: {
              description: 'Strefa',
              hint: 'strefy o tych samych nazwach są grupowane w jedną'
            },
            title: {
              description: 'Tytuł/Bilet',
            },
            price: {
              description: 'Cena',
            },
          },
        },

        // media: {
        //   type: 'file',
        //   description: 'T_GENERAL_FILE',
        //   endpoint: fileSchema.endpoint,
        //   validate: ['maxSize'],
        // },
      },
    },
  },
}

export default schema
