import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const homeEvents = (
  blockMultiSectionEndpoint,
  definitionRef = '#PageBlockMultiSection'
) => ({
  name: BLOCK_TYPES_IRI.HOME_EVENTS,
  label: 'Sekcja z Wydarzeniami i Spektaklami',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          type: 'string',
          label: 'Tytuł sekcji'
        },
        subtitle: {
          type: 'string',
          label: 'Nadtytuł sekcji'
        },
      },
    },
    multiSections: {
      type: 'collection',
      label: 'Wydarzenia i spektakle',
      titleAccessor: 'eventTitle', //`translations.${LANGS_IRI.PL}.title`,
      definitionRef: definitionRef,
      endpoint: blockMultiSectionEndpoint,
      properties: {

        // translations: {
        //   type: 'translation',
        //   properties: {

        //     title: {
        //       description: 'T_GENERAL_TITLE',
        //       label: 'T_GENERAL_TITLE',
        //       type: 'string',
        //     },

        //   },
        // },

        event: {
          type: 'resource',
          endpoint: `/api/events_select?pagination=false`,
          titleAccessor: `nativeTitle`,
          label: 'T_GENERAL_LINKED_EVENTS',
        },

      },
    },
  },
})
