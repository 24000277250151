import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const textWithVideo = (photoEndpoint, thumbEndpoint) => ({
  name: BLOCK_TYPES_IRI.TEXT_WITH_VIDEO,
  label: 'Blok tekstowy z wideo',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: 'textarea',
        },
        audiodescriptionbutton: {
          description : 'Audiodeskrypcja',
          hint: 'link',
          type: 'string',
        },
        signlanguagebutton: {
          description: 'Tłumacz migowy',
          hint: 'link',
          type: 'string',
        },
        text2: {
          type: 'textarea',
          description: 'Transkrypcja',
        },
        alt: {
          description: 'Tag alt',
          type: 'string',
        },
      },
    },
    // photoAuthor: {
    //   description: 'T_GENERAL_AUTHOR',
    // },
    media: {
      type: 'image',
      description: 'Zdjęcie, maska filmu (opcjonalnie)',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint + '?name=video_mask',
      },
      validate: ['maxSize'],
    },
    // media2: {
    //   type: 'image',
    //   description: 'T_GENERAL_PHOTO2',
    //   endpoint: photoEndpoint,
    //   thumbs: thumbEndpoint && {
    //     endpoint: thumbEndpoint+'?name=etr',
    //   },
    //   validate: ['maxSize'],
    // },
    // blockPosition: {
    //   type: 'choice',
    //   choices: {
    //     left: 'T_GENERAL_BLOCK_POSITION_LEFT',
    //     right: 'T_GENERAL_BLOCK_POSITION_RIGHT',
    //     full: 'T_GENERAL_BLOCK_POSITION_FULL',
    //   },
    //   validate: ['required'],
    // },
    // mediaThumbType: {
    //   type: 'resource',
    //   endpoint: `/api/thumb_types?pagination=false`,
    //   titleAccessor: `trans`,
    //   transTitle: true,
    //   label: 'T_GENERAL_MEDIA_PHOTO_TYPE',
    //   initialValue: { value: '/api/thumb_types/CROPPED', title: 'Kadr' },
    //   validate: ['required'],
    // },
    // file: {
    //   type: 'file',
    //   description: 'T_GENERAL_VIDEO',
    //   endpoint: photoEndpoint,
    //   validate: ['maxSize'],
    // },
    codeYt: {
      description: 'T_GENERAL_CODE_YT',
    },
  },
})
