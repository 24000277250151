import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import randomHash from 'random-hash'
import {
  FormControl,
  TextField,
  FormHelperText,
  Popper,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'
import { prop } from '_helpers/prop'
import { store } from '_store'
import { collectionConstants } from '_constants'
import { LANGS_IRI } from '_lib/langs'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
    marginTop: 10,
  },
  formControllFullWidth: {
    minWidth: '100%',
  },
  autocomplete: {
    minWidth: 260,
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const ResourceType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  endpoint = null,
  resources = null,
  titleAccessor,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
  storeCollectionId = null,
}) => {
  const [id] = useState(randomHash())

  const [options, setOptions] = useState([])
  const [choices, setChoices] = useState(resources || [])

  const handleChange = (e, option) => {
    setValue(name, option ? option.value : null)
    validateField(option ? option.value : null)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  function Tcompare(a, b) {
    if (a.translations && b.translations && a.translations[LANGS_IRI.PL]?.title && b.translations[LANGS_IRI.PL]?.title) {
      return new Intl.Collator().compare(
        a.translations[LANGS_IRI.PL].title,
        b.translations[LANGS_IRI.PL].title
      )
    } else if (a.title && b.title) {
      return new Intl.Collator().compare(a.title, b.title)
    }
    return 0
    // if (a.translations.pl.title < b.translations.pl.title) { return -1 } if (a.translations.pl.title > b.translations.pl.title) { return 1  } return 0
  }

  useEffect(() => {
    if (!endpoint || resources) {
      return
    }

    const tt =
      180 + parseInt(Math.random() * 5000, 10) * (storeCollectionId ? 1 : 0)

    setTimeout(function() {
      // store.dispatch({ type: collectionConstants.RESET_CONFIG, payload: {id : storeCollectionId} })
      if (
        storeCollectionId &&
        store.getState().collection[storeCollectionId]?.items
      ) {
        // console.log(storeCollectionId)
        const s = store.getState().collection[storeCollectionId].items
        setChoices(s)
      } else {
        const controller = new AbortController()
        const { signal } = controller

        fetchDataHandleAuthError(
          endpoint,
          'GET',
          { signal },
          response => {
            let xsc = response['hydra:member']
            if (xsc.length) {
              xsc = xsc.sort(Tcompare)
            }
            setChoices(xsc)

            const data = {
              id: storeCollectionId,
              config: { items: xsc },
            }

            store.dispatch({
              type: collectionConstants.SET_CONFIG,
              payload: data,
            })
          },
          error => {
            if (error.response.title === 'AbortError') {
              return
            }

            notification('error', error.response.detail, error.response.title)
          }
        )
        return () => controller.abort()
      }
    }, tt)
  }, [endpoint, resources, setChoices, storeCollectionId])

  useEffect(() => {
    const opt = choices?.map(resource => ({
      value: resource['@id'],
      title: prop(
        resource,
        titleAccessor,
        translate('T_GENERAL_MISSING_TITLE')
      ),
    }))
    setOptions(opt)
  }, [choices, titleAccessor])

  const classes = useStyles()

  return (
    <FormControl
      className={clsx(
        classes.formControl,
        fullWidth && classes.formControllFullWidth
      )}
      error={renderError && !!error}
    >
      <Autocomplete
        id={id}
        name={name}
        options={options}
        getOptionLabel={option => option.title}
        getOptionSelected={(option, value) => {
          return option.value === value.value
        }}
        onChange={handleChange}
        value={
          getValue(value)
            ? {
                value: getValue(value),
                title:
                  options.find(option => option.value === getValue(value))
                    ?.title || '',
              }
            : null
        }
        renderInput={params => (
          <TextField
            {...params}
            label={
              translate(label.text || label) +
              (validators && validators.includes('required') ? ' *' : '')
            }
            variant="outlined"
          />
        )}
        PopperComponent={props => (
          <Popper
            {...props}
            style={{ width: 'auto' }}
            placement="bottom-start"
          />
        )}
        disabled={disabled || !choices.length}
        classes={{ root: classes.autocomplete }}
      />
      <FormHelperText>
        {translate(renderError && error ? error : hint)}
      </FormHelperText>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            getValue(value) !== getValue(compareValue) && classes.compareNeq
          )}
        >
          {compareValue &&
            options.find(option => option.value === getValue(compareValue))
              ?.title}
        </div>
      )}
    </FormControl>
  )
}

const getValue = value => {
  if (!value) {
    return null
  }

  if (typeof value === 'string') {
    return value
  }

  return value['@id']
}

ResourceType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    }),
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    }),
  ]),
  compareValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    }),
  ]),
  compare: PropTypes.bool,
  endpoint: PropTypes.string,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    })
  ),
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
}
