import _block from 'pages/HomeBlocks/_blocks'
import mediaSchema from './media'

const schema = {
  endpoint: '/api/pages',
  access: 'page',
  resource: {
  },
  subresources: {
    block: {
      endpoint: '/api/page_blocks',
      definition: '#PageBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/page_block_multi_sections',
        '/api/page_block_thumbs',
        '/api/page_block_multi_section_thumbs',
      ).types,
    },
  },
}

export default schema
