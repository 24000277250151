import { BLOCK_TYPES_IRI } from '_lib/blockTypesBip'

export const textWithPhoto = (photoEndpoint, thumbEndpoint) => ({
  name: BLOCK_TYPES_IRI.TEXT_WITH_PHOTO,
  label: 'Blok tekstowy ze zdjęciem',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
        alt: {
          description: 'T_GENERAL_ALT',
          type: 'string',
        },
      },
    },
    media: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
      validate: ['maxSize'],
    },
  },
})
