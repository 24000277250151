export const modifyPropertiesByPage = (properties, resource, schema) => {
  let modifiedProperties = JSON.parse(JSON.stringify(properties))

  switch (resource.idName) {
    // Modify by page here
    case 'news':
      return modifiedProperties

    default:
      delete modifiedProperties.dateFrom
      return modifiedProperties
  }
}
