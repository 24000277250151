import React from 'react'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { prop } from '../../../_helpers/prop'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import routesSpectacle from '../routesSpectacle'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  listItem: {
    minWidth: 32,
  },
  menuTrigger: {
    borderRadius: 4,
    padding: 7,
  },
  menuTriggerOpen: {
    border: '1px solid #d3d4d5',
    borderBottomColor: '#fff',
    backgroundColor: '#fff',

    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
})

export const ReviewButton = ({ resource, accessor }) => {
  const classes = useStyles()
  const url = routesSpectacle().reviews.path.replace(
    ':id',
    prop(resource, 'uuid')
  )

  return (
    <div className={classes.root}>
      {/* <span>{prop(resource, accessor)}</span> */}
      {url && (
        <IconButton
          component={Link}
          to={url}
          color="primary"
          className={clsx(classes.sendButton)}
          variant="contained"
        >
          <MessageOutlinedIcon />
        </IconButton>
      )}
    </div>
  )
}
