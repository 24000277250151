import {
  homeEvents,
  homeRepertoire,
  homeCrew,
  homeEducation,
  homeTheater,
  homeNews,
  homeNewsletter,
  homeLabirynth,
} from '_schema/_blocks'

const schema = (
  photoEndpoint,
  blockMultiSectionEndpoint,
  thumbEndpoint,
  blockMultiSectionThumbEndpoint
) => ({
  types: [
    homeEvents(blockMultiSectionEndpoint),
    homeRepertoire(),
    homeCrew(blockMultiSectionEndpoint),
    homeEducation(
      photoEndpoint,
      thumbEndpoint + '?name[]=home_section_small&name[]=home_section'
    ),
    homeTheater(
      photoEndpoint,
      thumbEndpoint + '?name[]=home_section_small&name[]=home_section'
    ),
    homeNews(blockMultiSectionEndpoint, '#PageBlockMultiSection', true, 'Sekcja z aktualnościami'),
    homeLabirynth(
      blockMultiSectionEndpoint,
      photoEndpoint,
      blockMultiSectionThumbEndpoint +
        '?name[]=home_section_small&name[]=home_section',
      '#PageBlockMultiSection',
      'Sekcja "W labiryncie teatru"'
    ),
    homeNewsletter(),
  ],
})

export default schema
