import { BLOCK_TYPES_IRI } from '_lib/blockTypesBip'
import { LANGS_IRI } from '../../_lib/langs'

export const files = (photoEndpoint, blockFileEndpoint) => ({
  name: BLOCK_TYPES_IRI.FILES,
  label: 'T_GENERAL_BLOCK_TYPE_FILES',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    files: {
      type: 'collection',
      label: 'Pliki',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: '#BipPageBlockFile',
      endpoint: blockFileEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
            },
          },
        },
        file: {
          type: 'file',
          description: 'T_GENERAL_FILE',
          endpoint: photoEndpoint,
          validate: ['maxSize'],
        },
        // publishDate: {
        //   type: 'datetime',
        //   label: 'Czas publikacji załącznika',
        // },
        // creatorName: {
        //   description: 'Informację wytworzył',
        // },
        // personResponsible: {
        //   description: 'Informację opublikował',
        // },
      },
    },
  },
})
