import home from 'pages/Home/routes'
import admins from 'pages/Admins/routes'
import adminGroups from 'pages/AdminGroups/routes'
import loginEntries from 'pages/LoginEntries/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import settings from 'pages/Settings/routes'
import profile from 'pages/Profile/routes'
import pages from 'pages/Pages/routes'
import translatorEntries from 'pages/TranslatorEntries/routes'
import packageGalleries from 'pages/PackageGalleries/routes'
import packageGalleryItems from 'pages/PackageGalleryItems/routes'
import packageFiles from 'pages/PackageFiles/routes'
import packageFaqs from 'pages/PackageFaqs/routes'
import packageLogotypes from 'pages/PackageLogotypes/routes'
import packageLogotypeItems from 'pages/PackageLogotypeItems/routes'
import packagePricelist from 'pages/PackagePricelist/routes'
import practicalInformation from 'pages/PracticalInformations/routes'
import news from 'pages/News/routes'
import newsCategory from 'pages/NewsCategory/routes'
import event from 'pages/Event/routes'
import cycle from 'pages/EventCycle/routes'
import spectacle from 'pages/Event/routesSpectacle'
import eventCategory from 'pages/EventCategory/routes'
import eventRecipient from 'pages/EventRecipient/routes'
import eventLocation from 'pages/EventLocation/routes'
import offer from 'pages/Offers/routes'
import config from 'pages/Config/routes'
import configContact from 'pages/ConfigContact/routes'
import project from 'pages/Project/routes'
import homeBanner from 'pages/HomeBanner/routes'
import mainMenu from 'pages/MainMenu/routes'
import homeBlocks from 'pages/HomeBlocks/routes'
import crewItem from 'pages/CrewItem/routes'
import crewItemCoop from 'pages/CrewItemCooperat/routes'
import filmmakers from 'pages/CrewFilmmaker/routes'
import bipPage from 'pages/BipPage/routes'

const routes = () => [
  ...getArray(home()),
  ...getArray(pages()),
  ...getArray(packageGalleries()),
  ...getArray(packageGalleryItems()),
  ...getArray(packageFiles()),
  ...getArray(packageFaqs()),
  ...getArray(packageLogotypes()),
  ...getArray(packageLogotypeItems()),
  ...getArray(packagePricelist()),
  ...getArray(practicalInformation()),
  ...getArray(admins()),
  ...getArray(adminGroups()),
  ...getArray(loginEntries()),
  ...getArray(registryEntries()),
  ...getArray(translatorEntries()),
  ...getArray(settings()),
  ...getArray(profile()),
  ...getArray(news()),
  ...getArray(newsCategory()),
  ...getArray(event()),
  ...getArray(spectacle()),
  ...getArray(cycle()),
  ...getArray(eventCategory()),
  ...getArray(eventRecipient()),
  ...getArray(eventLocation()),
  ...getArray(offer()),
  ...getArray(config()),
  ...getArray(configContact()),
  ...getArray(project()),
  ...getArray(homeBanner()),
  ...getArray(mainMenu()),
  ...getArray(homeBlocks()),
  ...getArray(crewItem()),
  ...getArray(crewItemCoop()),
  ...getArray(filmmakers()),
  ...getArray(bipPage()),
]

const getArray = routes => Object.values(routes)

export default routes
