import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { BlockCollection } from 'components/block'
import { Form } from 'pages'
import { Sidebar } from './sidebar'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import { modifyPropertiesByPage } from './_helpers/modifyPropertiesByPage'
// import { commonConstants } from '_constants'
import { LANGS_IRI } from '_lib/langs'
import schema from '_schema/bipPage'
import routes from './routes'
import { Alert } from '@material-ui/lab'
import { translate } from '../../_helpers/translate'

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  form: {
    marginTop: 0,
  },
})

export const Edit = ({ schema: definitions, ...rest }) => {
  const dispatch = useDispatch()
  const [parentResource, setParentResource] = useState(null)
  const [showAlert, setShowAlert] = useState(false)

  const profile = useSelector(state => state.profile)
  const { match } = rest

  const iri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const isNotEditable = useCallback(
    resource => !profile?.openform && !resource.isEditable,
    [profile]
  )

  const history = useHistory()
  const handleSuccess = useCallback(() => {
    // dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })

    history.push(routes().index.path)
  }, [history, dispatch])

  const handleSuccessAndStay = useCallback(
    resource => {
      if (resource?.modified) {
        setShowAlert(true)
      } else {
        setShowAlert(false)
      }
    },
    [dispatch, setShowAlert]
  )

  const versionDisabled = useCallback(() => {
    return !showAlert
  }, [showAlert])

  const classes = useStyles()

  useEffect(() => {
    if (parentResource?.modified) {
      setShowAlert(true)
    } else {
      setShowAlert(false)
    }
  }, [parentResource, setShowAlert])

  const onVersionSuccess = useCallback(() => {
    setShowAlert(false)
  }, [setShowAlert])

  return (
    <div>
      {showAlert && (
        <Alert variant="filled" severity="info" style={{ marginTop: '30px' }}>
          {translate('T_MODULE_BIP_PAGE_ALERT_UNPUBLISHED_CHANGES')}
        </Alert>
      )}
      <Form
        iri={iri}
        isNotEditable={isNotEditable}
        collectionPath={routes().index.path}
        definitionSchema={definitions[schema.resource.definition]}
        customResourceSchema={schema.resource}
        modifyProperties={(properties, resource, schema) => {
          let modifiedProperties = modifyPropertiesByPage(
            properties,
            resource,
            schema
          )
          return addOpenformProperties(
            profile?.openform,
            modifiedProperties,
            resource,
            schema
          )
        }}
        method="PUT"
        url={iri}
        storeCollectionId={schema.endpoint}
        handleSuccess={handleSuccess}
        handleSuccessAndStay={handleSuccessAndStay}
        versionDisabled={versionDisabled}
        sidebar={true}
        sidebarTitleAccessor={`translations.${
          LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
        }.title`}
        sidebarStatAccessor={''}
        SidebarComponent={Sidebar}
        onVersionSuccess={onVersionSuccess}
        sidebarVersionEndpoint={schema.version_endpoint}
        fieldsFullWidth={false}
        width="100%"
        key={iri}
        setParentResource={setParentResource}
        classes={{ root: classes.form }}
      >
        <BlockCollection
          endpoint={schema.subresources.block.endpoint}
          pid={match.params.id}
          parentIri={iri}
          definitionSchema={definitions[schema.subresources.block.definition]}
          types={schema.subresources.block.types}
          contextualHint={schema.subresources.block.contextualHint}
          statable={true}
          key={iri}
        />
      </Form>
    </div>
  )
}
