import { getCurrentRoute } from './getCurrentRoute'

export const generateBreadcrumbs = (path, routes) => generatePath(path, routes)

const generatePath = (path, routes, init = true) => {
  const matchedRoute = getCurrentRoute(path, routes)?.route

  if (!matchedRoute) {
    return []
  }

  const subtitle =
    init && matchedRoute.subtitle
      ? [
          {
            path: null,
            title: matchedRoute.subtitle,
          },
        ]
      : []

  const parentMatchedRoute = getCurrentRoute(matchedRoute.parent, routes)?.route

  if(matchedRoute.id === 'terms_edit') {
    matchedRoute.indexPath = matchedRoute.path.split('/terms/')[0] + '/terms'
    matchedRoute.title = 'Termin'
  }
  if(matchedRoute.id === 'reviews_edit') {
    matchedRoute.indexPath = matchedRoute.path.split('/reviews/')[0] + '/reviews'
    matchedRoute.title = 'Recenzja'
  }

  if (
    !matchedRoute.parent ||
    parentMatchedRoute.indexPath === matchedRoute.indexPath
  ) {
    return [
      ...[
        {
          path: matchedRoute.indexPath || path,
          title: matchedRoute.title,
          ...Object.assign(
            {},
            matchedRoute.parent && { self: matchedRoute.parent }
          ),
        },
      ],
      ...subtitle,
    ]
  }

  return [
    ...generatePath(matchedRoute.parent, routes, false),
    ...[
      {
        path: matchedRoute.indexPath || path,
        title: matchedRoute.title,
      },
    ],
    ...subtitle,
  ]
}
