import React, { useState } from 'react'
import { Button, Collapse, TextField } from '@material-ui/core'
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined'
import { translate } from '_helpers/translate'

export const EventContactShtType = ({
  name,
  label,
  label2,
  label3,
  label4,
  label5,
  label6,
  hint = null,
  initialValue,
  value,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  getValue,
  setError,
  fullWidth = false,
  classes = {},
  name2,
  name3,
  name4,
  name5,
  name6,
  mainLabel,
  ...rest
}) => {
  const handleChange = (name, e) => {
    const x = getValueThis(e.target.value)

    setTr(function(s) {
      let r = {
        ...s,
      }
      r[name] = x
      return r
    })

    setValue(name, x || null)
  }

  const [show, setShow] = useState(false)
  const [tr, setTr] = useState(
    getValue(`translations`)
      ? getValue(`translations`)[rest.translationLang]
      : null
  )

  const handleClick = () => {
    setShow(!show)
  }

  return (
    <>
      <Button
        onClick={handleClick}
        // variant="outlined"
        color="primary"
        size="small"
        style={{ paddingLeft: 0, xmargin: 0 }}
      >
        <ContactPhoneOutlinedIcon style={{ marginRight: 10 }} />
        {mainLabel}
      </Button>
      {show && (
        <Collapse in={show}>
          <TextField
            name={name}
            type="text"
            label={translate(label.text || label)}
            value={tr && tr[name] ? tr[name] : ''}
            disabled={disabled}
            onChange={e => handleChange(name, e)}
            error={renderError && !!error}
            helperText={translate(renderError && error ? error : hint)}
            fullWidth={fullWidth}
          />

          <TextField
            name={name2}
            type="text"
            label={translate(label2)}
            value={tr && tr[name2] ? tr[name2] : ''}
            disabled={disabled}
            onChange={e => handleChange(name2, e)}
            error={renderError && !!error}
            helperText={translate(renderError && error ? error : hint)}
            fullWidth={fullWidth}
          />

          <TextField
            name={name3}
            type="text"
            label={translate(label3)}
            value={tr && tr[name3] ? tr[name3] : ''}
            disabled={disabled}
            onChange={e => handleChange(name3, e)}
            error={renderError && !!error}
            helperText={translate(renderError && error ? error : hint)}
            fullWidth={fullWidth}
          />

          <TextField
            name={name4}
            type="text"
            label={translate(label4)}
            value={tr && tr[name4] ? tr[name4] : ''}
            disabled={disabled}
            onChange={e => handleChange(name4, e)}
            error={renderError && !!error}
            helperText={translate(renderError && error ? error : hint)}
            fullWidth={fullWidth}
          />

          <TextField
            name={name5}
            type="text"
            label={translate(label5)}
            value={tr && tr[name5] ? tr[name5] : ''}
            disabled={disabled}
            onChange={e => handleChange(name5, e)}
            error={renderError && !!error}
            helperText={translate(renderError && error ? error : hint)}
            fullWidth={fullWidth}
          />

          <TextField
            name={name6}
            type="textarea"
            label={translate(label6)}
            value={tr && tr[name6] ? tr[name6] : ''}
            disabled={disabled}
            onChange={e => handleChange(name6, e)}
            error={renderError && !!error}
            helperText={translate(renderError && error ? error : hint)}
            fullWidth={fullWidth}
            minRows={5}
            maxRows={8}
            multiline={true}
            FormHelperTextProps={{ style: { marginLeft: 0 } }}
          />
        </Collapse>
      )}
    </>
  )
}

const getValueThis = value => (value === '' ? null : value)

EventContactShtType.propTypes = {}
