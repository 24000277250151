import {
  text,
  logotypes,
} from '_schema/_blocks'

const schema = (
  photoEndpoint,
  blockThumbEndpoint,
  blockMultiSectionEndpoint,
  blockMultiSectionThumbEndpoint,
  blockBranchesEndpoint,
  blockDidascaliaEndpoint,
  blockContactEndpoint
) => ({
  types: [
    text(),
    logotypes(blockMultiSectionEndpoint, '#EventBlockMultiSection', true),
  ],
})

export default schema
