import photoSchema from './photo'

const schema = {
  endpoint: '/api/crew_items',
  access: 'crewItem',
  resource: {
    definition: '#CrewItem',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          name: {
            description: 'Imię'
          },
          surname: {
            description: 'Nazwisko',
            validate: ['required']
          },
          text:{
            description: 'Biogram',
            type: 'tinymce'
          },
          job: {
            type:'string',
            description: 'Stanowisko osoby',
            hint: 'wyswietlane w bloku z dyrekcją'
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          photoDescription: {
            type:'string',
            description: 'Podpis zdjęcia, np autor zdjęcia'
          },
          slug: {},
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/api/crew_item_thumbs',
        },
        validate: ['maxSize'],
      },
      hideInList: {
        description: 'Ukryj na liście aktorów',
        type: 'boolean',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  subresources: {
    images: {
      endpoint: '/api/crew_item_images',
      definition: '#CrewItemImage',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'Tytuł',
            },
            alt:{
              description: 'Atl',
              type: 'string'
            },
          },
        },
        media: {
          type: 'image',
          description: 'T_GENERAL_MEDIA_PHOTO',
          endpoint: photoSchema.endpoint.single,
          endpointMultiple: photoSchema.endpoint.multiple,
          thumbs: {
            endpoint: '/api/crew_item_image_thumbs',
          },
          validate: ['maxSize'],
        },
      },
    },
    sections: {
      endpoint: '/api/crew_item_sections',
      definition: '#CrewItemSection',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'Tytuł',
            },
            text:{
              description: 'Tekst',
              type: 'tinymce'
            },
            videowizytowka:{
              type: 'ytWithSht',
              name: 'audiodescriptionbutton',
              name2: 'signlanguagebutton',
              name3: 'text2',
              name4: 'ytCode',
              label: 'Audiodeskrypcja',
              label2: 'Tłumacz migowy',
              label3: 'Transkrypcja',
              label4: 'Kod youtube',
              description: 'Videowizytówka',
              mainLabel: 'Videowizytówka',
            },
            audiodescriptionbutton: {
              type: 'nullType'
            },
            signlanguagebutton: {
              type: 'nullType'
            },
            text2: {
              type: 'nullType'
            },
            ytCode: {
              type: 'nullType'
            }
          },
        },
        autoobsada: {
          description: 'Wylistuj aktualną obsadę w sekcji',
        }
      },
    },
  },
}

export default schema
