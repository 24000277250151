import adminSchema from '_schema/admin'
import adminGroupSchema from '_schema/adminGroup'
import loginEntry from '_schema/loginEntry'
import registryEntry from '_schema/registryEntry'
import page from '_schema/page'
import translatorEntry from '_schema/translatorEntry'


import event from '_schema/event'
import eventCategory from '_schema/eventCategory'
import eventLocations from '_schema/eventLocations'
import eventRecipients from '_schema/eventRecipients'
import generalArticle from '_schema/generalArticle'
import homeBanner from '_schema/homeBanner'
import news from '_schema/news'
import newsCategory from '_schema/newsCategory'
import packageFaq from '_schema/packageFaq'
import packageFile from '_schema/packageFile'
import packageGallery from '_schema/packageGallery'
import packageLogotype from '_schema/packageLogotype'
import practicalInformation from '_schema/practicalInformation'
import project from '_schema/project'
import projectArticle from '_schema/projectArticle'
import offer from '_schema/offer'


const resources = () => ({
  [page.access]: 'Struktura stron i sekcje strony głównej',
  [adminSchema.access]: 'Administrators',
  [adminGroupSchema.access]: 'Administrator Groups',
  [loginEntry.access]: 'Login entries',
  [registryEntry.access]: 'Registry entries',
  [translatorEntry.access]: 'Translator entries',
  // [customer.access]: 'Klienci',
  // [customerGroup.access]: 'Klienci - grupy',
  [event.access]: 'Wydarzenia',
  [eventCategory.access]: 'Wydarzenia - kategorie',
  [eventLocations.access]: 'Wydarzenia - miejsca',
  [eventRecipients.access]: 'Wydarzenia - udogodnienia',
  // [generalArticle.access]: 'Ogólne artykuły (Portal)',
  [homeBanner.access]: 'Banner główny',
  [news.access]: 'Aktualności',
  [newsCategory.access]: 'Aktualności - kategorie',
  // [packageFaq.access]: 'Zestawy FAQ',
  [packageFile.access]: 'Zestawy plików',
  [packageGallery.access]: 'Galerie',
  [packageLogotype.access]: 'Pakiety logotypów',
  [practicalInformation.access]: 'Oferty specjalne',
  // [project.access]: 'Projekty',
  // [projectArticle.access]: 'Projekty - artykuły',
  [offer.access]: 'Oferty',
})

export default resources
