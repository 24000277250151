import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const textWithLink = (photoEndpoint, thumbEndpoint) => ({
  name: BLOCK_TYPES_IRI.TEXT_WITH_LINK,
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_LINK',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: 'textarea',
        },
        link: {
          type: 'string',
          description: 'T_GENERAL_BLOCK_LINK',
        },
        alt: {
          description: 'Tag alt',
          type: 'string',
        },
      },
    },

    media: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
      validate: ['maxSize'],
    },
    blockPosition: {
      type: 'choice',
      choices: {
        left: 'T_GENERAL_BLOCK_POSITION_LEFT',
        right: 'T_GENERAL_BLOCK_POSITION_RIGHT',
      },
      validate: ['required'],
    },
  },
})
