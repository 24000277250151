import React from 'react'
import { Alert } from "@material-ui/lab"
import { LANGS_IRI } from "_lib/langs"

const schema = {
  endpoint: '/api/event_terms',
  clone_endpoint: '/api/event_terms/clone',
  access: 'event',
  resource: {
    definition: '#EventTerm',
    properties: {
      dateFrom: {
        type: 'datetime',
        description: 'Data i czas terminu',
        validate: ['required'],
      },
      recipients: {
        type: 'multipleResource',
        endpoint: `/api/event_recipients?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_EVENT_RECIPIENTS_SELECT',
        hint: function() {
          return (
            <Alert severity="info" style={{ background: 'none', padding: 0 }}>
              można wybrać maksymalnie dwa udogodnienia
            </Alert>
          )
        },
      },

      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
