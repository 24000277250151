import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const citation = () => ({
  name: BLOCK_TYPES_IRI.CITATION,
  label: 'T_GENERAL_BLOCK_TYPE_CITATION',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Cytat',
          type: 'textarea',
        },
      },
    },
    // photoAuthor: {
    //   description: 'T_GENERAL_AUTHOR',
    // },
  },
})
