import _blockBip from './_blockBip'
import mediaSchema from './media'

const schema = {
  endpoint: '/api/bip_page_articles',
  clone_endpoint: '/api/bip_page_articles/clone',
  version_endpoint: '/api/bip_page_articles/version',
  resource: {
    definition: '#BipPageArticle',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {},
        },
      },
      creatorName: {
        description: 'Informację wytworzył',
      },
      personResponsible: {
        description: 'Informację opublikował',
      },

      dateFrom: {
        type: 'date',
        description: 'Data artykułu',
      },

      stan: {
        type: 'choice',
        description: 'Stan',
        choices: {
          current: 'Aktualne',
          finish: 'Zakończone',
          delete: 'Usunięte'
        },
        fullWidth: false
      },
      act: {
        type: 'choice',
        description: 'Typ',
        choices: {
          act: 'Podlega ustawie',
          noact: 'Nie podlega ustawie',
        },
        fullWidth: false
      },
      sin: {
        type: 'choice',
        description: 'Typ',
        choices: {
          done: 'Rozstrzygnięty',
          unknow: 'Nierozstrzygnięty',
        },
        fullWidth: false
      },

    },
  },
  subresources: {
    block: {
      endpoint: '/api/bip_page_article_blocks',
      definition: '#BipPageArticleBlock',
      types: _blockBip(
        mediaSchema.endpoint,
        '/api/bip_page_article_block_thumbs',
        '/api/bip_page_article_block_files',
        '/api/bip_page_article_block_contacts'
      ).types,
    },
  },

}

export default schema
