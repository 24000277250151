import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { SearchOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { filters as filterComponents } from './filters'
import { FilterError } from './_helpers'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    columnGap: 10,
  },

  disabled: {
    cursor: 'default',
    color: theme.palette.disabled,
    '&:hover': {
      color: theme.palette.disabled,
    },
  },

  filterWrapper: {
    marginBottom: 15,
  },
}))

export const FilterBarHorizontal = ({
  filters,
  disabled = false,
  handleFilters,
}) => {
  const classes = useStyles()

  const [filterState, setFilterState] = useState(filters)

  const setFilter = (name, value) => {
    setFilterState(state => ({
      ...state,
      [name]: {
        ...state[name],
        value,
      },
    }))
  }

  const handleFilter = useCallback(
    (name, value) => {
      handleFilters({
        [name]: {
          value,
        },
      })
    },
    [handleFilters]
  )

  const handleSearch = () => {
    if (disabled) {
      return
    }

    handleFilters(filterState)
  }

  useEffect(() => {
    setFilterState(filters)
  }, [filters, setFilterState])

  return Object.keys(filterState).filter(key => filterState[key].title)
    .length ? (
    <>
      <div className={classes.root}>
        {Object.keys(filterState)
          .filter(key => filterState[key].title)
          .map(key => {
            if (
              typeof filterState[key].type === 'string' &&
              !filterComponents[filterState[key].type]
            ) {
              throw new FilterError(filterState[key].type)
            }

            const FilterComponent =
              typeof filterState[key].type === 'string'
                ? filterComponents[filterState[key].type]
                : filterState[key].type

            return (
              <div className={classes.filterWrapper} key={`filter-${key}`}>
                <FilterComponent
                  name={key}
                  title={filterState[key].title}
                  value={filterState[key].value}
                  disabled={disabled}
                  handleEnter={handleSearch}
                  setFilter={setFilter}
                  handleFilter={handleFilter}
                  endpoint={filterState[key].endpoint}
                />
              </div>
            )
          })}

        <Button
          onClick={handleSearch}
          disabled={disabled}
          variant="contained"
          startIcon={<SearchOutlined />}
          color="secondary"
        >
          {translate('T_GENERAL_SEARCH')}
        </Button>
      </div>
    </>
  ) : null
}

FilterBarHorizontal.propTypes = {
  filters: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
        .isRequired,
      title: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    })
  ).isRequired,
  disabled: PropTypes.bool,
  handleFilters: PropTypes.func.isRequired,
}
