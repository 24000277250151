import React, { useMemo } from 'react'
import { NotFound } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { translate } from '_helpers/translate'
import schema from '_schema/event'
import itemSchema from '_schema/eventTerm'
import routesEvent from 'pages/Event/routes'
import routesSpectacle from 'pages/Event/routesSpectacle'
import routesCycle from 'pages/EventCycle/routes'

export const Terms = ({ schema: definitions, type, ...rest }) => {
  const routes =
    type === 'event'
      ? routesEvent
      : type === 'spectacle'
      ? routesSpectacle
      : routesCycle

  const { match } = rest

  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const itemColumns = useMemo(
    () => [
      {
        header: translate('Data terminu'),
        accessor: `title`,
        // filterName: 'fullName',
        // filterable: true,
        width: '73%',
      },

      {
        ...operations(`title`, true, true, false, true),
        width: '15%',
      },
    ],
    []
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj termin')}
        buttonPath={`${routes().termsNew.path.replace(':id', match.params.id)}`}
        hideButton={false}
      ></CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${itemSchema.endpoint}.get`}
        endpoint={itemSchema.endpoint}
        definition={itemSchema.resource.definition}
        storeCollectionId={`${itemSchema.endpoint}?iri[parent]=${parentIri}`}
        defaultFilters={{
          'iri[parent]': parentIri,
        }}
        parameters={[
          {
            name: 'iri[parent]',
            in: 'query',
            type: 'string',
            value: false,
          },
        ]}
        columns={itemColumns}
        customResourceSchema={itemSchema.resource}
        defaultSorters={{ createdAt: 'desc' }}
        orderable={false}
        statable={false}
        isRowLinkable={true}
        editPath={routes().termsEdit.path.replace(':pid', match.params.id)}
        autoWidth={false}
      />
    </Paper>
  )
}
