import _blockEvent from './_blockEventCycle'
import photoSchema from './photo'
import mediaSchema from './media'

const schema = {
  endpoint: '/api/events',
  clone_endpoint: '/api/events/clone',
  access: 'event',
  resource: {
    definition: '#Event',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title1: {
            description: 'Tytuł'
          },

          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
          },

          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          photoDescription: {
            type: 'textarea',
            description: 'Opis pod zdjęciem',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {},
        },
      },

      mediaDetail: {
        type: 'image',
        description: 'Zdjęcie',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/event_thumbs', //?name=
          property: 'mediaDetail',
        },
      },

      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },

    },
  },
  subresources: {
    blockEvent: {
      endpoint: '/api/event_blocks',
      definition: '#EventBlock',
      types: _blockEvent(
        mediaSchema.endpoint,
        '/api/event_block_thumbs',
        '/api/event_block_multi_sections',
        '/api/event_block_multi_section_thumbs',
        '/api/event_block_branches',
        '/api/event_block_didascalias',
        '/api/event_block_contacts'
      ).types,
    },
  },
}

export default schema
