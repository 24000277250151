import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const pages = (
  blockMultiSectionEndpoint,
  definitionRef = '#PageBlockMultiSection'
) => ({
  name: BLOCK_TYPES_IRI.PAGES,
  label: 'Blok z przekierowaniami do podstron',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    multiSections: {
      type: 'collection',
      label: 'Strony',
      titleAccessor: 'pageTitle', //`translations.${LANGS_IRI.PL}.title`,
      definitionRef: definitionRef,
      endpoint: blockMultiSectionEndpoint,
      properties: {
        // translations: {
        //   type: 'translation',
        //   properties: {
        //     title: {
        //       description: 'T_GENERAL_TITLE',
        //       label: 'T_GENERAL_TITLE',
        //       type: 'string',
        //     },
        //   },
        // },
        page: {
          type: 'resource',
          endpoint: `/api/pages_select?pagination=false`,
          titleAccessor: `translations.${LANGS_IRI.PL}.title`, //`nativeTitle`,
          label: 'Strony',
        },
      },
    },
  },
})
