import React from 'react'

export const HintType = ({
  hint,
}) => {

  return (
    <>
    {typeof hint ==='function' ? hint() : hint}
    </>
  )
}

HintType.propTypes = {}
