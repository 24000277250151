export const BLOCK_TYPES = {
  '/api/bip_block_types/TEXT': 'text',
  '/api/bip_block_types/TEXT_WITH_PHOTO': 'text_with_photo',
  '/api/bip_block_types/FILES': 'files',
  '/api/bip_block_types/CONTACT': 'contact',
}

export const BLOCK_TYPES_IRI = {
  TEXT: '/api/bip_block_types/TEXT',
  TEXT_WITH_PHOTO: '/api/bip_block_types/TEXT_WITH_PHOTO',
  FILES: '/api/bip_block_types/FILES',
  CONTACT: '/api/bip_block_types/CONTACT',
}
