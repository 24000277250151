import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import { Title } from 'components/Title'
import { Form } from 'pages'
import { translate } from '_helpers/translate'
import schema from '_schema/mainMenu'
import routes from './routes'

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  form: {
    marginTop: 0,
  },
})

export const Edit = ({ schema: definitions, ...rest }) => {

  const { match } = rest

  const iri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const history = useHistory()
  const handleSuccess = useCallback(
    resource => {
      history.push(
        resource.parent
          ? routes().indexParent.path.replace(
              ':id',
              resource.parent[process.env.REACT_APP_RESOURCE_ID]
            )
          : routes().index.path
      )
    },
    [history]
  )
  const handleCancel = () => {
    history.goBack()
  }

  const classes = useStyles()

  return (
    <Form
      iri={iri}
      collectionPath={routes().index.path}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      method="PUT"
      url={iri}
      storeCollectionId={schema.endpoint}
      handleSuccess={handleSuccess}
      handleCancel={handleCancel}
      // handleSuccessAndStay={handleSuccessAndStay}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      // SidebarComponent={Sidebar}
      fieldsFullWidth={false}
      header={
        <Container maxWidth="xl" className={classes.header}>
          <Title>{translate('T_GENERAL_EDIT')}</Title>
        </Container>
      }
      width="100%"
      key={iri}
      classes={{ root: classes.form }}
    >

    </Form>
  )
}
