import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const cycledesc = (thumbEndpoint, photoEndpoint) => ({
  name: BLOCK_TYPES_IRI.CYCLE_DESC,
  label: 'Blok "Opis cyklu"',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        subtitle: {},
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
        alt: {
          description: 'Tag alt',
          type: 'string',
        },
      },
    },
    media3: {
      type: 'image',
      description: 'Zdjęcie',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint + '?name[]=cadr&name[]=cadr_big',
      },
      validate: ['maxSize'],
    },
  },
})
