import { text, textWithPhoto, files, contact } from '_schema/_blocksBip'

const schema = (
  photoEndpoint,
  blockThumbEndpoint,
  blockFileEndpoint,
  blockContactEndpoint
) => ({
  types: [
    text(),
    textWithPhoto(photoEndpoint, blockThumbEndpoint),
    files(photoEndpoint, blockFileEndpoint),
    contact(blockContactEndpoint),
  ],
})

export default schema
