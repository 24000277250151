import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const homeTheater = (photoEndpoint, thumbEndpoint) => ({
  name: BLOCK_TYPES_IRI.HOME_THEATER,
  label: 'Sekcja ze zdjęciem "Rzemiosło teatralne"',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: 'textarea',
        },
        link: {
          type: 'string',
          description: 'T_GENERAL_BLOCK_LINK',
        },
        alt: {
          description: 'Tag alt',
          type: 'string',
        },
      },
    },

    media: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
      validate: ['maxSize'],
    },

  },
})
