import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { Title } from 'components/Title'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { NotFound } from 'pages'
import { translate } from '_helpers/translate'
import { HBlockCollection } from './HBlockCollection'
import schema from '_schema/homeBlock'

const useStyles = makeStyles({
  section: {
    width: 700,
    marginBottom: 50,
    paddingBottom: 30,
    borderBottom: '1px solid #ddd',
    '&:last-child': {
      marginBottom: 0,
      paddingBottom: 0,
      borderBottom: 'none',
    },
  },
  section_title: {
    marginBottom: 20,
  },
})

export const Edit = ({ schema: definitions }) => {
  const [state, setState] = useState({
    isFetching: true,
    fetchError: false,
    resource: null,
    blocks: null,
  })

  const [blockTypes, setBlockTypes] = useState(schema.subresources.block.types)

  useEffect(
    () => {
      const controller = new AbortController()
      const { signal } = controller

      setState({
        ...state,
        isFetching: true,
        fetchError: false,
      })

      fetchDataHandleAuthError(
        `${schema.endpoint}?idName[]=home`,
        'GET',
        { signal },
        response => {
          let sortedResponse = {}
          response['hydra:member'].forEach(item => {
            if (!!item.idName) {
              sortedResponse[item.idName] = item
            }
          })

          setState({
            isFetching: false,
            resource:
              response['hydra:member'].length && sortedResponse['home']
                ? sortedResponse['home']
                : {},
          })
        },
        error => {
          if (error.response.title === 'AbortError') {
            return
          }

          notification('error', error.response.detail, error.response.title)
          setState({
            isFetching: false,
            fetchError: true,
          })
        }
      )

      return () => controller.abort()
    },
    // eslint-disable-next-line
    []
  )

  const { isFetching, fetchError, resource } = state

  const classes = useStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <Title className={classes.header}>
        {translate('Strona główna zarządzanie sekcjami')}
      </Title>

      <div>
        <br />
      </div>

      {blockTypes?.length > 0 && resource?.uuid && (
        <HBlockCollection
          endpoint={schema.subresources.block.endpoint}
          pid={resource.uuid}
          parentIri={`${schema.endpoint}/${resource.uuid}`}
          definitionSchema={definitions[schema.subresources.block.definition]}
          types={blockTypes}
          contextualHint={schema.subresources.block.contextualHint}
          statable={true}
          key={`${schema.endpoint}/${resource.uuid}`}
        />
      )}
    </Paper>
  )
}
