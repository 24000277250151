import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const events = (
  blockMultiSectionEndpoint,
  definitionRef = '#PageBlockMultiSection',
  withAutomatic = false,
  title = null
) => {
  let out = {
    name: BLOCK_TYPES_IRI.EVENTS,
    label: title ? title : 'Blok z Wydarzeniami i Spektaklami',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'string',
            label: 'Tytuł bloku',
          },
          subtitle: {
            type: 'string',
            label: 'Nadtytuł bloku',
          },
        },
      },
      multiSections: {
        type: 'collection',
        label: 'Wydarzenia i spektakle',
        titleAccessor: 'eventTitle', //`translations.${LANGS_IRI.PL}.title`,
        definitionRef: definitionRef,
        endpoint: blockMultiSectionEndpoint,
        properties: {
          event: {
            type: 'resource',
            endpoint: `/api/events_select?pagination=false`,
            titleAccessor: `nativeTitle`,
            label: 'T_GENERAL_LINKED_EVENTS',
          },
        },
      },
    },
  }

  if (withAutomatic) {
    out = {
      ...out,
      properties: {
        ...out.properties,
        automatic: {
          label: 'Automatycznie losowe wydarzenia i spektakle z repertuaru',
          hint: 'wyswietla automatycznie, zamiast ręcznie wybranych'
        },
      },
    }
  }

  return out
}
