const schema = {
  endpoint: '/api/config',
  resource: {
    definition: '#Config',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          contactTitle: {
            type: 'string',
            description: 'Nnagłówek kontaktu',
          },
          contactAddress: {
            description: 'Adres kontaktu',
            type: 'textarea',
          },

          contactData: { description: 'Dodatkowe dane', type: 'textarea' },
          contactData2: { description: 'Dodatkowe dane - dłuższe dane', type: 'textarea' },

          contactUrl1: { description: 'Link teatru', type: 'string', validate:['url'] },

          contactUrl2: { description: 'Link labirytnu', type: 'string' , validate:['url']},

          contactUrl3: { description: 'Link bip', type: 'string' , validate:['url']},

          contactCasesTitle: { description: 'Nagłówek kasa teatru', type: 'string' },

          contactCasesAddress: { description: 'Adres kasa teartu', type: 'textarea' },

          contactCasesEmail: { description: 'Email kasa teatru', type: 'string' , validate:['email']},

          contactCasesPhone: { description: 'Telefon kasa teatru', type: 'string' , validate:['phone']},


          contactScene1Title: { description: 'Scena 1 nagłówek', type: 'string' },

          contactScene1Address: { description: 'Scena 1 dane/adres', type: 'textarea' },


          contactScene2Title: { description: 'Scena 2 nagłówek', type: 'string' },

          contactScene2Address: { description: 'Scena 2 dane/adress', type: 'textarea' },


          contactMapTitle: { description: 'Tytuł/Nagłówek pod mapą', type: 'string' },

          contactMapDesc: { description: 'Opis pod mapą', type: 'textarea' },
        },
      },
    },
  },
}

export default schema
