import {
  PeopleOutlined,
  DescriptionOutlined,
  SettingsOutlined,
  AssignmentTurnedInSharp,
  ViewListSharp,
  Event,
  Dashboard,
} from '@material-ui/icons'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import home from 'pages/Home/routes'
import admins from 'pages/Admins/routes'
import adminGroups from 'pages/AdminGroups/routes'
import loginEntries from 'pages/LoginEntries/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import settings from 'pages/Settings/routes'
import profile from 'pages/Profile/routes'
import pages from 'pages/Pages/routes'
import translatorEntries from 'pages/TranslatorEntries/routes'
import packageGalleries from 'pages/PackageGalleries/routes'
import packageFiles from 'pages/PackageFiles/routes'
import packageFaqs from 'pages/PackageFaqs/routes'
import packageLogotypes from 'pages/PackageLogotypes/routes'
import packagePricelist from 'pages/PackagePricelist/routes'

import practicalInformation from 'pages/PracticalInformations/routes'
import news from 'pages/News/routes'
import newsCategory from 'pages/NewsCategory/routes'
import event from 'pages/Event/routes'
import cycle from 'pages/EventCycle/routes'
import spectacle from 'pages/Event/routesSpectacle'
import eventCategory from 'pages/EventCategory/routes'
import eventRecipient from 'pages/EventRecipient/routes'
import eventLocation from 'pages/EventLocation/routes'
import offer from 'pages/Offers/routes'
import config from 'pages/Config/routes'
import configContact from 'pages/ConfigContact/routes'
import project from 'pages/Project/routes'
import homeBanner from 'pages/HomeBanner/routes'
import customer from 'pages/Customer/routes'
import customerGroup from 'pages/CustomerGroup/routes'
import generalArticle from 'pages/GeneralArticle/routes'
import mainMenu from 'pages/MainMenu/routes'
import homeBlocks from 'pages/HomeBlocks/routes'
import crewItem from 'pages/CrewItem/routes'
import crewItemCoop from 'pages/CrewItemCooperat/routes'
import filmmakers from 'pages/CrewFilmmaker/routes'
import bipPage from 'pages/BipPage/routes'

const menu = () => [
  home().index,
  pages().index,
  {
    title: 'T_MENU_PACKAGES',
    icon: ViewListSharp,
    items: [
      packageGalleries().index,
      packageFiles().index,
      packageFaqs().index,
      packageLogotypes().index,
      packagePricelist().index,
    ],
  },
  {
    title: 'T_MENU_PORTAL',
    icon: Dashboard,
    items: [homeBlocks().edit, homeBanner().index, mainMenu().index],
  },
  {
    title: 'T_MENU_EVENTS',
    icon: Event,
    items: [
      event().index,
      spectacle().index,
      eventLocation().index,
      eventCategory().index,
      eventRecipient().index,
    ],
  },
  cycle().index,
  {
    title: 'T_MENU_NEWS',
    icon: AssignmentTurnedInSharp,
    items: [news().index, newsCategory().index],
  },
  {
    title: 'Oferty',
    icon: offer().index.icon,
    items: [offer().index, practicalInformation().index],
  },
  {
    title: 'Aktorzy',
    icon: crewItem().index.icon,
    items: [crewItem().index, crewItemCoop().index],
  },
  filmmakers().index,
  {
    title: 'T_MENU_BIP',
    icon: AccountBalanceIcon,
    items: [bipPage().index],
  },
  {
    title: 'Administrators',
    icon: PeopleOutlined,
    items: [admins().index, adminGroups().index],
  },
  {
    title: 'T_MENU_REGISTRIES',
    icon: DescriptionOutlined,
    items: [registryEntries().index, loginEntries().index],
  },
  {
    title: 'T_MODULE_SETTINGS',
    icon: SettingsOutlined,
    items: [
      config().edit,
      configContact().edit,
      settings().index,
      profile().index,
    ],
  },
  translatorEntries().index,
]

export default menu
