import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { LANGS_IRI } from '_lib/langs'
import schema from '_schema/translatorEntry'
import routes from './routes'
import { exportselected } from './table/exportselected'
import { ExportImportCards } from './table/exportimportcard'
import { TitleCell } from './table/cells/TitleCell'
import { useSelector } from 'react-redux'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_CODE'),
        accessor: 'code',
        filterable: true,
        sortable: true,
        width: '25%',
      },
      {
        header: `${translate('T_GENERAL_TRANSLATION')} PL`,
        accessor: `translations.${LANGS_IRI.PL}.title`,
        filterName: 'translations.title',
        filterable: true,
        sortable: false,
        width: '25%',
        Cell: TitleCell,
      },

      {
        header: `${translate('T_GENERAL_TRANSLATION')} EN`,
        accessor: `translations.${LANGS_IRI.EN}.title`,
        width: '25%',
        Cell: TitleCell,
      },

      {
        ...operations(
          `translations.${LANGS_IRI.PL}.title`,
          true,
          true,
          false,
          true
        ),
        width: '13%',
      },
    ],
    []
  )

  const profile = useSelector(state => state.profile)
  const [reloadInvoke, setReloadInvoke] = React.useState(true)
  const reloadInvokeFunction = val => {
    setReloadInvoke(val)
  }

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_TRANSLATOR_ENTRIES_NEW_BUTTON')}
        buttonPath={routes().new.path}
      >

        <>
          {translate('T_MODULE_TRANSLATOR_ENTRIES')}
          {profile.openform && (
            <ExportImportCards reloadInvokeFunction={reloadInvokeFunction} />
          )}
        </>

      </CollectionHeader>
      {reloadInvoke && ( <SchemableCollection
        component={CollectionTable}
        horizontalFilterBar={true}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        parameters={[
          {
            name: 'translations.title',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'code',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[code]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ord]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
        orderable={true}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        selectable={
          profile.openform
            ? [exportselected.go(), exportselected.delete()]
            : [exportselected.delete()]
        }
      />)}
    </Paper>
  )
}
