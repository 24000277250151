import { text, gallery, logotypes, events, cycledesc, multimediaEent } from '_schema/_blocks'

const schema = (
  photoEndpoint,
  blockThumbEndpoint,
  blockMultiSectionEndpoint,
  blockMultiSectionThumbEndpoint,
  blockBranchesEndpoint,
  blockDidascaliaEndpoint,
  blockContactEndpoint
) => ({
  types: [
    text(),
    gallery(),
    multimediaEent(),
    logotypes(blockMultiSectionEndpoint, '#EventBlockMultiSection'),
    events(blockMultiSectionEndpoint, '#EventBlockMultiSection', true),
    cycledesc(blockThumbEndpoint, photoEndpoint),
  ],
})

export default schema
