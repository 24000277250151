import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { countWithButton } from 'components/table/_columns/countWithButton'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/event'
import routesEvent from './routes'
import routesSpectacle from './routesSpectacle'
import { ReviewButton } from './_helper/_button_reviews'
import { LANGS_IRI } from '_lib/langs'

export const Collection = ({ type }) => {
  const routes = type === 'event' ? routesEvent : routesSpectacle
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        //accessor: `translations.${LANGS_IRI.PL}.title`,
        accessor: 'nativeTitle',
        sortName: 'nativeTitle',
        filterName: 'nativeTitle',
        filterable: true,
        sortable: true,
        width: '35%',
      },
      {
        ...countWithButton(`submissionsCount`, 'Terminy', routes().terms.path),
        width: '5%',
      },
      {
        header: translate('Recenzje'),
        sortable: false,
        filterable: false,
        accessor: `translations.${LANGS_IRI.PL}.title`,
        Cell: ReviewButton,
        width: '5%',
        hidden: type === 'event' ? true : false
      },
      {
        ...operations(
          `nativeTitle`,
          true,
          true,
          true,
          true,
          false,
          schema.clone_endpoint
        ),
        width: '10%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate(
          type === 'event' ? 'T_MODULE_EVENT_NEW_BUTTON' : 'Dodaj spektakl'
        )}
        buttonPath={routes().new.path}
      >
        {translate(type === 'event' ? 'Wydarzenia' : 'Spektakle')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        defaultFilters={{
          type: type,
        }}
        parameters={[
          {
            name: 'nativeTitle',
            in: 'query',
            type: 'string',
          },
          {
            name: 'type',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[ord]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[nativeTitle]',
            in: 'query',
            type: 'string',
          },
        ]}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        storeCollectionId={`${schema.endpoint}-${type}`}
      />
    </Paper>
  )
}
