import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const audio = fileEndpoint => ({
  name: BLOCK_TYPES_IRI.AUDIO,
  label: 'T_GENERAL_BLOCK_TYPE_AUDIO',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
        text2: {
          type: 'textarea',
          description: 'Transkrypcja',
        },
      },
    },
    file: {
      type: 'file',
      description: 'T_GENERAL_AUDIO',
      endpoint: fileEndpoint,
      validate: ['maxSize'],
    },
  },
})
