import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const homeRepertoire = () => ({
  name: BLOCK_TYPES_IRI.HOME_REPERTOIRE,
  label: 'Sekcja "Teraz gramy"',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          type: 'string',
          label: 'Tytuł sekcji',
        },
      },
    },
  },
})
