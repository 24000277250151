import React from 'react'
import { Form } from 'pages'
import schema from '_schema/offer'
import routes from './routes'
import {addOpenformProperties} from "./_helpers/addOpenformProperties";
import {useSelector} from "react-redux";

export const New = ({ schema: definitions }) => {
  const profile = useSelector(state => state.profile)

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      method="POST"
      modifyProperties={properties =>
        addOpenformProperties(profile.group?.isOpenform, properties)
      }
      url={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      storeCollectionId={schema.endpoint}
      width={'100%'}
    />
  )
}
