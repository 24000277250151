import React from 'react'
import { Form } from 'pages'
import schema from '_schema/event'
import routesEvent from './routes'
import routesSpectacle from './routesSpectacle'
import { modifyProperties } from './_helper/_modifyproperties'

export const New = ({ schema: definitions, type }) => {
  const profile = null
  const routes = type === 'event' ? routesEvent : routesSpectacle
  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      method="POST"
      url={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      storeCollectionId={schema.endpoint}
      width={'100%'}
      defaultData={{ type: type }}
      modifyProperties={properties =>
        modifyProperties(profile?.openform, properties, type)
      }
    />
  )
}
