import React from 'react'

export const NullType = ({
  ...rest
}) => {

  return (
    ''
  )
}

NullType.propTypes = {}
