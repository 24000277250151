import React from 'react'
import { Form } from 'pages'
import schema from '_schema/eventReview'
import routesEvent from 'pages/Event/routes'
import routesSpectacle from 'pages/Event/routesSpectacle'

export const Edit = ({ schema: definitions,type, ...rest }) => {
  const { match } = rest
  const routes = type === 'event' ? routesEvent : routesSpectacle
  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().reviews.path.replace(':id',match.params.pid)}
      storeCollectionId={schema.endpoint}
      sidebar={false}
      width={600}
    >
    </Form>
  )
}
