export const modifyProperties = (isOpenform, properties, type) => {
  let modifiedProperties = JSON.parse(JSON.stringify(properties))

  if (isOpenform) {
  }

  if(type === 'event') {
    delete modifiedProperties.media
    delete modifiedProperties.translations.properties.duration
    delete modifiedProperties.translations.properties.breaks
    delete modifiedProperties.translations.properties.incomingRoles
    delete modifiedProperties.roles
    delete modifiedProperties.makers
    delete modifiedProperties.author
    delete modifiedProperties.director
    delete modifiedProperties.authorText
    delete modifiedProperties.directorText
    delete modifiedProperties.statics
    delete modifiedProperties.translations.properties.eventEduShtType
    delete modifiedProperties.translations.properties.avardText
    delete modifiedProperties.galleryType
  } else {
    delete modifiedProperties.cycle
    delete modifiedProperties.mediaDetail
    delete modifiedProperties.translations.properties.price
    delete modifiedProperties.translations.properties.eventContactShtType
  }

  return modifiedProperties
}
