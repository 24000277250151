export const BLOCK_TYPES = {
  '/api/block_types/TEXT': 'text',
  '/api/block_types/TEXT_WITH_PHOTO': 'text_with_photo',
  '/api/block_types/TEXT_WITH_VIDEO': 'text_with_video',
  '/api/block_types/TEXT_WITH_LINK': 'text_with_link',
  '/api/block_types/GALLERY': 'gallery',
  '/api/block_types/FILES': 'files',
  '/api/block_types/FAQ': 'faq',
  '/api/block_types/LOGOTYPES': 'logotypes',
  '/api/block_types/AUDIO': 'audio',
  '/api/block_types/BRANCHES': 'branches',
  '/api/block_types/ETR': 'etr',
  '/api/block_types/CONTACT': 'contact',
  '/api/block_types/CITATION': 'citation',
  '/api/block_types/AUTHOR': 'author',
  '/api/block_types/MULTI_SECTION': 'multi_section',
  '/api/block_types/PRACTICAL_INFORMATION': 'practical_information',
  '/api/block_types/ISSUU': 'issuu',
  '/api/block_types/PAGES': 'pages',
  '/api/block_types/EVENTS': 'events',
  '/api/block_types/NEWS': 'news',
  '/api/block_types/CYCLE_DESC': 'cycle_desc',
  '/api/block_types/PAGES_TILE': 'pages_tile',
  '/api/block_types/HEADMASTER': 'headmaster',
  '/api/block_types/SCENES': 'scenes',
  '/api/block_types/HARMONOGRAM': 'harmonogram',
  '/api/block_types/MULTIMEDIA': 'multimedia',

  '/api/block_types/HOME_EVENTS': 'home_events',
  '/api/block_types/HOME_REPERTOIRE': 'home_repertoire',
  '/api/block_types/HOME_CREW': 'home_crew',
  '/api/block_types/HOME_EDUCATION': 'home_education',
  '/api/block_types/HOME_THEATER': 'home_theater',
  '/api/block_types/HOME_NEWS': 'home_news',
  '/api/block_types/HOME_LABIRYNTH': 'home_labirynth',
  '/api/block_types/HOME_NEWSLETTER': 'home_newsletter',
}

export const BLOCK_TYPES_IRI = {
  TEXT: '/api/block_types/TEXT',
  TEXT_WITH_PHOTO: '/api/block_types/TEXT_WITH_PHOTO',
  TEXT_WITH_VIDEO: '/api/block_types/TEXT_WITH_VIDEO',
  TEXT_WITH_LINK: '/api/block_types/TEXT_WITH_LINK',
  GALLERY: '/api/block_types/GALLERY',
  FILES: '/api/block_types/FILES',
  FAQ: '/api/block_types/FAQ',
  LOGOTYPES: '/api/block_types/LOGOTYPES',
  AUDIO: '/api/block_types/AUDIO',
  BRANCHES: '/api/block_types/BRANCHES',
  ETR: '/api/block_types/ETR',
  CONTACT: '/api/block_types/CONTACT',
  CITATION: '/api/block_types/CITATION',
  AUTHOR: '/api/block_types/AUTHOR',
  MULTI_SECTION: '/api/block_types/MULTI_SECTION',
  PRACTICAL_INFORMATION: '/api/block_types/PRACTICAL_INFORMATION',
  ISSUU: '/api/block_types/ISSUU',
  PAGES: '/api/block_types/PAGES',
  HARMONOGRAM: '/api/block_types/HARMONOGRAM',
  EVENTS: '/api/block_types/EVENTS',
  NEWS: '/api/block_types/NEWS',
  CYCLE_DESC: '/api/block_types/CYCLE_DESC',
  PAGES_TILE: '/api/block_types/PAGES_TILE',
  HEADMASTER: '/api/block_types/HEADMASTER',
  SCENES: '/api/block_types/SCENES',
  MULTIMEDIA: '/api/block_types/MULTIMEDIA',

  HOME_EVENTS: '/api/block_types/HOME_EVENTS',
  HOME_REPERTOIRE: '/api/block_types/HOME_REPERTOIRE',
  HOME_CREW: '/api/block_types/HOME_CREW',
  HOME_EDUCATION: '/api/block_types/HOME_EDUCATION',
  HOME_THEATER: '/api/block_types/HOME_THEATER',
  HOME_NEWS: '/api/block_types/HOME_NEWS',
  HOME_LABIRYNTH: '/api/block_types/HOME_LABIRYNTH',
  HOME_NEWSLETTER: '/api/block_types/HOME_NEWSLETTER',
}
