import React from 'react'
import { Form } from 'pages'
import schema from '_schema/crewItem'
import routes from './routes'
import { EmbeddedCollection } from 'components/embedded'
import { LANGS_IRI } from '_lib/langs'
import { translate } from '_helpers/translate'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width={'100%'}
    >
      <div style={{ marginTop: '30px' }}>
        <EmbeddedCollection
          endpoint={`${schema.subresources.sections.endpoint}?iri[parent]=${iri}&pagination=false`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.sections.properties}
          definitionSchema={definitions[schema.subresources.sections.definition]}
          headerTitle={translate('Sekcje rozwijane')}
          panelTitle={translate('Sekcja')}
          titleAccessor={`translations.${LANGS_IRI.PL}.title`}
          statable={true}
        />
      </div>

      <div style={{ marginTop: '30px' }}>
        <EmbeddedCollection
          endpoint={`${schema.subresources.images.endpoint}?iri[parent]=${iri}&pagination=false`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.images.properties}
          definitionSchema={definitions[schema.subresources.images.definition]}
          headerTitle={translate('Zdjęcia/galeria aktora')}
          panelTitle={translate('Zdjęcie')}
          titleAccessor={`translations.${LANGS_IRI.PL}.title`}
          statable={true}
        />
      </div>
    </Form>
  )
}
