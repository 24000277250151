import React from 'react'
import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import packageLogotype from '../packageLogotype'
import { LANGS_IRI } from '../../_lib/langs'
import { Alert } from '@material-ui/lab'

export const logotypes = (
  blockMultiSectionEndpoint,
  definitionRef = '#PageBlockMultiSection',
  withSectionText = false
) => {
  let out = {
    name: BLOCK_TYPES_IRI.LOGOTYPES,
    label: 'T_GENERAL_BLOCK_TYPE_LOGOTYPES',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      multiSections: {
        type: 'collection',
        label: 'Sekcje z logotypami',
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        definitionRef: definitionRef,
        endpoint: blockMultiSectionEndpoint,
        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {
                description: 'Tytuł sekcji',
                type: 'string',
                hint: function() {
                  return (
                    <Alert
                      severity="info"
                      style={{ background: 'none', padding: 0 }}
                    >
                      można pominąć, wówczas nie bedzie widoczna nazwa sekcji
                    </Alert>
                  )
                },
              },
            },
          },

          logotype: {
            type: 'resource',
            endpoint: `${packageLogotype.endpoint}?pagination=false`,
            titleAccessor: 'title',
            description: 'Select package logotype',
          },
        },
      },
    },
  }

  if(withSectionText) {
    out.properties.multiSections.properties.translations.properties = {
      ...out.properties.multiSections.properties.translations.properties,
      description: {
        description: 'Krótki opis nad grupą logotypów',
        type: 'textarea'
      }
    }
  }
  return out
}
