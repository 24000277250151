const schema = {
  endpoint: '/api/crew_filmmakers',
  access: 'crewFilmmaker',
  resource: {
    definition: '#CrewFilmmaker',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          name: {
            description: 'Imię'
          },
          surname: {
            description: 'Nazwisko',
            validate: ['required']
          },
          roleName: {
            type: 'string',
            description: 'Domyślna nazwa/funkcja',
            hint: 'np. reżyser, scenografia, autor itp.'
          },
          text:{
            description: 'Biogram',
            type: 'tinymce'
          },
          // alt: {
          //   description: 'T_GENERAL_ALT',
          // },
          // is off
          // photoDescription: {
          //   type:'string',
          //   description: 'Podpis zdjęcia, np autor zdjęcia'
          // },
          // slug: {},
        },
      },
      //is off
      // media: {
      //   type: 'image',
      //   description: 'T_GENERAL_MEDIA_PHOTO',
      //   endpoint: photoSchema.endpoint.single,
      //   endpointMultiple: photoSchema.endpoint.multiple,
      //   thumbs: {
      //     endpoint: '/api/crew_item_thumbs',
      //   },
      //   validate: ['maxSize'],
      // },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },

}

export default schema
