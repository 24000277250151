import _block from './_blockNews'
import photoSchema from './photo'
import mediaSchema from './media'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/news',
  clone_endpoint: '/api/news/clone',
  access: 'news',
  resource: {
    definition: '#News',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            // type: 'tinymceTitle',
          },
          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          photoDescription: {
            type: 'textarea',
            description: 'Opis pod zdjęciem'
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {},
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/news_thumbs',
          property: 'media',
        },
      },
      dateFrom: {
        type: 'date',
        description: 'T_GENERAL_DATE_FROM',
        validate: ['required'],
      },
      category: {
        type: 'resource',
        endpoint: `/api/news_categories?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_GENERAL_CATEGORY',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/news_blocks',
      definition: '#NewsBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/news_block_thumbs',
        '/api/news_block_multi_sections',
        '/api/news_block_multi_section_thumbs',
        '/api/news_block_branches',
        '/api/news_block_didascalias',
        '/api/news_block_contacts'
      ).types,
    },
  },
}

export default schema
