// import React from 'react'
import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const scenes = (
  photoEndpoint,
  blockMultiSectionThumbEndpoint,
  blockMultiSectionEndpoint,
  definitionRef = '#PageBlockMultiSection'
) => {
  let out = {
    name: BLOCK_TYPES_IRI.SCENES,
    label: 'Blok "Sceny teatru"',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      multiSections: {
        type: 'collection',
        label: 'Kafle bloku',
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        definitionRef: definitionRef,
        endpoint: blockMultiSectionEndpoint,
        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {
                description: 'Tytuł',
                type: 'string',
              },
              subtitle: {
                description: 'Podtytuł',
                hint: 'np. ulica, pogrubiony tekst pod głównym tytułem',
                type: 'string',
              },
              description: {
                type: 'textarea',
                description: 'Krótki opis',
              },
              link: {
                type: 'string',
                description: 'T_GENERAL_BLOCK_LINK',
              },
              alt: {
                description: 'Tag alt',
                type: 'string',
              },
            },
          },

          page: {
            type: 'resource',
            endpoint: `/api/pages_select?pagination=false`,
            titleAccessor: `translations.${LANGS_IRI.PL}.title`, //`nativeTitle`,
            label: 'Strony',
            hint: 'można wybrać stronę, jeśli link nie wpisany',
          },

          media2: {
            type: 'media[image]',
            description: 'T_GENERAL_MEDIA_PHOTO',
            endpoint: photoEndpoint,
            thumbs: blockMultiSectionThumbEndpoint && {
              endpoint: blockMultiSectionThumbEndpoint+'?name[]=home_section_small&name[]=home_section',
            },
            validate: ['maxSize'],
          },
        },
      },
    },
  }

  return out
}
