import React, {useMemo} from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/crewItem'
import routes from './routes'
import { LANGS_IRI } from '../../_lib/langs'
import { MainCell } from './table/cells/MainCell'

export const Collection = () => {
  const type = 'cooperat'
  const columns = useMemo(
    () => [
      {
        header: translate('Imię i nazwisko'),
        accessor: `translations.${LANGS_IRI.PL}.fullName`,
        filterable: true,
        sortable: true,
        Cell: MainCell,
        width: '70%',
      },
      {
        ...operations(`translations`, true, true, true, true, false),
        width: '10%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj aktora')}
        buttonPath={routes().new.path}
        hideButton={false}
      >
        {translate('Aktorzy współpracujący')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        storeCollectionId={`${schema.endpoint}-${type}`}
        defaultFilters={{
          type: type,
        }}
        parameters={[
          {
            name: 'type',
            in: 'query',
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
