export const modifyArticlePropertiesByParentPage = (
  properties,
  resource,
  schema
) => {
  let modifiedProperties = JSON.parse(JSON.stringify(properties))

  switch (resource.idName) {
    // Modify by page here
    case 'offers':
      return modifiedProperties

    default:
      delete modifiedProperties.stan
      delete modifiedProperties.sin
      delete modifiedProperties.act
      return modifiedProperties
  }
}
