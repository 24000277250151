import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from './table/CollectionTable'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/bipPage'
import routes from './routes'
import { Alert } from '@material-ui/lab'

export const Collection = ({ wrapWithPaper = true, showNewButton = true }) => {
  const profile = useSelector(state => state.profile)

  const WrapComponent = wrapWithPaper ? Paper : 'div'

  const [homepageAlert, setHomepageAlert] = useState(false)

  return (
    <WrapComponent>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_PAGES_NEW_BUTTON')}
        buttonPath={routes().new.path}
        hideButton={!showNewButton}
      >
        {translate('T_MODULE_PAGES')}
      </CollectionHeader>
      {homepageAlert && (
        <Alert
          variant="filled"
          severity="error"
          style={{ marginBottom: '20px' }}
        >
          {translate('T_MODULE_BIP_PAGE_ALERT_NO_HOMEPAGE')}
        </Alert>
      )}
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        customResourceSchema={schema.resource}
        defaultFilters={{
          'exists[parent]': false,
          'exists[originalUuid]': false,
        }}
        defaultSorters={{ ord: 'desc' }}
        selectable={[publish.on(), publish.off()]}
        isRowSelectable={resource => profile?.openform || resource.isStatable}
        editPath={routes().edit.path}
        // setHomepageAlert={setHomepageAlert}//not used
        autoWidth={false}
        parameters={[
          {
            name: 'exists[parent]',
            in: 'query',
            required: false,
            type: 'boolean',
          },
          {
            name: 'exists[originalUuid]',
            in: 'query',
            required: false,
            type: 'boolean',
          },
          {
            name: 'order[ord]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </WrapComponent>
  )
}

Collection.propTypes = {
  wrapWithPaper: PropTypes.bool,
  showNewButton: PropTypes.bool,
}
